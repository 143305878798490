import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, EmptyState } from '@bynder/design-system';
import { IconLock } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { ErrorPage } from '../errors.styled';

const NoAccess = () => {
    const { translate } = useTranslate();
    const history = useHistory();

    return (
        <ErrorPage>
            <EmptyState
                icon={<IconLock />}
                title={translate('pages.errors.no-access.title')}
                text={translate('pages.errors.no-access.text')}
                actions={
                    <Button
                        variant="secondary"
                        aria-label={translate('pages.errors.no-access.button')}
                        onClick={() => history.replace('/')}
                    >
                        {translate('pages.errors.no-access.button')}
                    </Button>
                }
            />
        </ErrorPage>
    );
};
export default NoAccess;
