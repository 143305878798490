import React from 'react';
import { SizeData } from 'packages/store/newDesign/types';

export enum MediaTypeSelection {
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
}

export type Sizes = {
    [format: string]: { width: number; height: number; format: string };
};

export enum FormatKeys {
    CUSTOM = 'CUSTOM',
    VIDEO_LANDSCAPE = 'VIDEO_LANDSCAPE',
    VIDEO_SQUARE = 'VIDEO_SQUARE',
    VIDEO_PORTRAIT = 'VIDEO_PORTRAIT',
    VIDEO_IG_POST = 'VIDEO_IG_POST',
    VIDEO_IG_REELS = 'VIDEO_IG_REELS',
    VIDEO_IG_STORY = 'VIDEO_IG_STORY',
    VIDEO_FB_POST = 'VIDEO_FB_POST',
    VIDEO_FB_STORY = 'VIDEO_FB_STORY',
    VIDEO_LI_POST = 'VIDEO_LI_POST',
    VIDEO_LI_STORY = 'VIDEO_LI_STORY',
    VIDEO_LI_ADD = 'VIDEO_LI_ADD',
    VIDEO_GA_SQUARE = 'VIDEO_GA_SQUARE',
    VIDEO_GA_SMALL_SQUARE = 'VIDEO_GA_SMALL_SQUARE',
    VIDEO_GA_INLINE_AD = 'VIDEO_GA_INLINE_AD',
    VIDEO_GA_LARGE_AD = 'VIDEO_GA_LARGE_AD',
    VIDEO_TW_AD = 'VIDEO_TW_AD',
    VIDEO_TW_POST = 'VIDEO_TW_POST',
    VIDEO_TW_VIDEO = 'VIDEO_TW_VIDEO',
    VIDEO_TT_VIDEO = 'VIDEO_TT_VIDEO',
    VIDEO_TT_BG = 'VIDEO_TT_BG',
    VIDEO_YT_VIDEO_AD = 'VIDEO_YT_VIDEO_AD',
    VIDEO_YT_SHORTS = 'VIDEO_YT_SHORTS',
    VIDEO_YT_4K = 'VIDEO_YT_4K',
    IMAGE_LANDSCAPE = 'IMAGE_LANDSCAPE',
    IMAGE_SQUARE = 'IMAGE_SQUARE',
    IMAGE_PORTRAIT = 'IMAGE_PORTRAIT',
    IMAGE_IG_POST = 'IMAGE_IG_POST',
    IMAGE_IG_STORY = 'IMAGE_IG_STORY',
    IMAGE_FB_ADD = 'IMAGE_FB_ADD',
    IMAGE_FB_APP_AD = 'IMAGE_FB_APP_AD',
    IMAGE_FB_COVER = 'IMAGE_FB_COVER',
    IMAGE_FB_POST = 'IMAGE_FB_POST',
    IMAGE_FB_STORY = 'IMAGE_FB_STORY',
    IMAGE_FB_EVENT_COVER = 'IMAGE_FB_EVENT_COVER',
    IMAGE_LI_POST = 'IMAGE_LI_POST',
    IMAGE_LI_STORY = 'IMAGE_LI_STORY',
    IMAGE_GA_SQUARE = 'IMAGE_GA_SQUARE',
    IMAGE_GA_SMALL_SQUARE = 'IMAGE_GA_SMALL_SQUARE',
    IMAGE_GA_INLINE_AD = 'IMAGE_GA_INLINE_AD',
    IMAGE_GA_LARGE_AD = 'IMAGE_GA_LARGE_AD',
    IMAGE_TW_AD = 'IMAGE_TW_AD',
    IMAGE_TW_HEADER = 'IMAGE_TW_HEADER',
    IMAGE_TW_POST = 'IMAGE_TW_POST',
    IMAGE_TT_VIDEO = 'IMAGE_TT_VIDEO',
    IMAGE_TT_BG = 'IMAGE_TT_BG',
    IMAGE_YT_ART = 'IMAGE_YT_ART',
    IMAGE_YT_LOGO = 'IMAGE_YT_LOGO',
    IMAGE_YT_THUMBNAIL = 'IMAGE_YT_THUMBNAIL',
}

export const videoSizes: Sizes = {
    LANDSCAPE: { width: 1920, height: 1080, format: FormatKeys.VIDEO_LANDSCAPE },
    SQUARE: { width: 1080, height: 1080, format: FormatKeys.VIDEO_SQUARE },
    PORTRAIT: { width: 1080, height: 1920, format: FormatKeys.VIDEO_PORTRAIT },
    IG_POST: { width: 1080, height: 1080, format: FormatKeys.VIDEO_IG_POST },
    IG_REELS: { width: 1080, height: 1920, format: FormatKeys.VIDEO_IG_REELS },
    IG_STORY: { width: 1080, height: 1920, format: FormatKeys.VIDEO_IG_STORY },
    FB_POST: { width: 940, height: 788, format: FormatKeys.VIDEO_FB_POST },
    FB_STORY: { width: 1080, height: 1920, format: FormatKeys.VIDEO_FB_STORY },
    LI_POST: { width: 1200, height: 1200, format: FormatKeys.VIDEO_LI_POST },
    LI_STORY: { width: 1080, height: 1920, format: FormatKeys.VIDEO_LI_STORY },
    LI_ADD: { width: 1920, height: 1920, format: FormatKeys.VIDEO_LI_ADD },
    GA_SQUARE: { width: 250, height: 250, format: FormatKeys.VIDEO_GA_SQUARE },
    GA_SMALL_SQUARE: { width: 200, height: 200, format: FormatKeys.VIDEO_GA_SMALL_SQUARE },
    GA_INLINE_AD: { width: 300, height: 250, format: FormatKeys.VIDEO_GA_INLINE_AD },
    GA_LARGE_AD: { width: 336, height: 280, format: FormatKeys.VIDEO_GA_LARGE_AD },
    TW_AD: { width: 1600, height: 900, format: FormatKeys.VIDEO_TW_AD },
    TW_POST: { width: 1600, height: 900, format: FormatKeys.VIDEO_TW_POST },
    TW_VIDEO: { width: 1600, height: 900, format: FormatKeys.VIDEO_TW_VIDEO },
    TT_VIDEO: { width: 1080, height: 1920, format: FormatKeys.VIDEO_TT_VIDEO },
    TT_BG: { width: 1080, height: 1920, format: FormatKeys.VIDEO_TT_BG },
    YT_VIDEO_AD: { width: 1920, height: 1080, format: FormatKeys.VIDEO_YT_VIDEO_AD },
    YT_SHORTS: { width: 1080, height: 1920, format: FormatKeys.VIDEO_YT_SHORTS },
    YT_4K: { width: 3840, height: 2160, format: FormatKeys.VIDEO_YT_4K },
};

export const imageSizes: Sizes = {
    LANDSCAPE: { width: 1920, height: 1080, format: FormatKeys.IMAGE_LANDSCAPE },
    SQUARE: { width: 1080, height: 1080, format: FormatKeys.IMAGE_SQUARE },
    PORTRAIT: { width: 1080, height: 1920, format: FormatKeys.IMAGE_PORTRAIT },
    IG_POST: { width: 1080, height: 1080, format: FormatKeys.IMAGE_IG_POST },
    IG_STORY: { width: 1080, height: 1920, format: FormatKeys.IMAGE_IG_STORY },
    FB_AD: { width: 1200, height: 628, format: FormatKeys.IMAGE_FB_ADD },
    FB_APP_AD: { width: 810, height: 450, format: FormatKeys.IMAGE_FB_APP_AD },
    FB_COVER: { width: 1640, height: 924, format: FormatKeys.IMAGE_FB_COVER },
    FB_POST: { width: 940, height: 788, format: FormatKeys.IMAGE_FB_POST },
    FB_STORY: { width: 1080, height: 1920, format: FormatKeys.IMAGE_FB_STORY },
    FB_EVENT_COVER: { width: 1920, height: 1080, format: FormatKeys.IMAGE_FB_EVENT_COVER },
    LI_POST: { width: 1200, height: 1200, format: FormatKeys.IMAGE_LI_POST },
    LI_STORY: { width: 1080, height: 1920, format: FormatKeys.IMAGE_LI_STORY },
    GA_SQUARE: { width: 250, height: 250, format: FormatKeys.IMAGE_GA_SQUARE },
    GA_SMALL_SQUARE: { width: 200, height: 200, format: FormatKeys.IMAGE_GA_SMALL_SQUARE },
    GA_INLINE_AD: { width: 300, height: 250, format: FormatKeys.IMAGE_GA_INLINE_AD },
    GA_LARGE_AD: { width: 336, height: 280, format: FormatKeys.IMAGE_GA_LARGE_AD },
    TW_AD: { width: 1600, height: 900, format: FormatKeys.IMAGE_TW_AD },
    TW_HEADER: { width: 1500, height: 500, format: FormatKeys.IMAGE_TW_HEADER },
    TW_POST: { width: 1600, height: 900, format: FormatKeys.IMAGE_TW_POST },
    TT_VIDEO: { width: 1080, height: 1920, format: FormatKeys.IMAGE_TT_VIDEO },
    TT_BG: { width: 1080, height: 1920, format: FormatKeys.IMAGE_TT_BG },
    YT_ART: { width: 2560, height: 1440, format: FormatKeys.IMAGE_YT_ART },
    YT_LOGO: { width: 800, height: 800, format: FormatKeys.IMAGE_YT_LOGO },
    YT_THUMBNAIL: { width: 1280, height: 720, format: FormatKeys.IMAGE_YT_THUMBNAIL },
};

export type BlankItem = {
    label: string;
    size: SizeData;
    icon: React.ReactElement;
};

export type BlankItemProps = { selected: boolean };

export type Categories = { label: string; items: BlankItem[] }[];
