export enum ReviewStatus {
    DRAFT = 'DRAFT',
    IN_REVIEW = 'IN_REVIEW',
    EDITS_REQUESTED = 'EDITS_REQUESTED',
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED',
}

export type ReviewState = {
    entityId?: number;
    status?: ReviewStatus;
    comment?: string;
    entityGrantedPermissions: any;
    actionDate?: string;
    saving: boolean;
    savingStatus?: ReviewStatus;
    error?: any;
};

export enum actionTypes {
    REVIEW_CREATE_REQUEST = 'REVIEW_CREATE_REQUEST',
    REVIEW_CREATE_FAILURE = 'SHARING_FETCH_SUCCESS',
    REVIEW_CREATE_SUCCESS = 'REVIEW_CREATE_SUCCESS',
    REVIEW_CREATE_INIT = 'REVIEW_CREATE_INIT',
}
