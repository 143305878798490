import { actionTypes, ColorPalettesState } from './types';

const initialState: ColorPalettesState = {
    palettes: [],
    palette: null,
    isFetching: false,
    totalItems: 0,
    isPaletteFetching: false,
    editInProgress: false,
    deleteInProgress: false,
    createInProgress: false,
    palettesErrors: undefined,
    paletteErrors: undefined,
};

export default function colorPalettes(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_COLOR_PALETTES_REQUEST: {
            return {
                ...state,
                isFetching: true,
                totalItems: 0,
                palettes: [],
                palettesErrors: undefined,
            };
        }
        case actionTypes.GET_COLOR_PALETTES_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                totalItems: state.totalItems,
                palettes: action.palettes,
            };
        }
        case actionTypes.GET_COLOR_PALETTES_FAILURE: {
            return {
                ...state,
                isFetching: false,
                totalItems: 0,
                palettes: [],
                palettesErrors: action.errors,
            };
        }
        case actionTypes.GET_COLOR_PALETTE_REQUEST: {
            return {
                ...state,
                isPaletteFetching: true,
                palette: null,
                paletteErrors: undefined,
            };
        }
        case actionTypes.GET_COLOR_PALETTE_SUCCESS: {
            return {
                ...state,
                isPaletteFetching: false,
                palette: action.palette,
            };
        }
        case actionTypes.GET_COLOR_PALETTE_FAILURE: {
            return {
                ...state,
                isPaletteFetching: false,
                palette: null,
                paletteErrors: action.errors,
            };
        }
        case actionTypes.ADD_COLOR_PALETTE_REQUEST: {
            return {
                ...state,
                createInProgress: true,
            };
        }
        case actionTypes.ADD_COLOR_PALETTE_SUCCESS: {
            return {
                ...state,
                createInProgress: false,
            };
        }
        case actionTypes.ADD_COLOR_PALETTE_FAILURE: {
            return {
                ...state,
                createInProgress: false,
                palette: null,
            };
        }
        case actionTypes.EDIT_COLOR_PALETTE_REQUEST:
            return {
                ...state,
                editInProgress: true,
            };
        case actionTypes.EDIT_COLOR_PALETTE_SUCCESS:
            return {
                ...state,
                editInProgress: false,
            };
        case actionTypes.EDIT_COLOR_PALETTE_FAILURE:
            return {
                ...state,
                editInProgress: false,
            };
        case actionTypes.DELETE_COLOR_PALETTE_REQUEST:
            return {
                ...state,
                deleteInProgress: true,
            };

        case actionTypes.DELETE_COLOR_PALETTE_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
            };

        case actionTypes.DELETE_COLOR_PALETTE_FAILURE:
            return {
                ...state,
                deleteInProgress: false,
            };

        default:
            return state;
    }
}
