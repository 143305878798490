import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { searchActionTypes, CategorySearchState } from './types';

const initialState: CategorySearchState = {
    categories: [],
    isFetching: false,
    totalItems: 0,
    searching: false,
    fetchOptions: {
        sort: ['date_created', 'desc'],
        size: 1000,
        page: 0,
        search: '',
        searchTime: undefined,
        includeCollectionId: null,
        type: undefined,
        subType: 'TEMPLATE',
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
        creativeType: undefined,
    },
};

export default function searchCategory(state = initialState, action) {
    switch (action.type) {
        case searchActionTypes.CATEGORIES_SEARCH_RESET:
            return {
                ...initialState,
            };
        case searchActionTypes.CATEGORIES_SEARCH_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case searchActionTypes.CATEGORIES_SEARCH_START_FETCH:
            return {
                ...state,
                isFetching: true,
                searching: action.searching,
                categories: action.emptyBeforeFetch ? [] : state.categories,
                fetchOptions: {
                    ...state.fetchOptions,
                    searchTime: action.searchTime,
                },
            };
        case searchActionTypes.CATEGORIES_SEARCH_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case searchActionTypes.CATEGORIES_SEARCH_SUCCESS_FETCH:
            return {
                ...state,
                isFetching: false,
                categories: [
                    ...state.categories,
                    ...action.categories.map((item) => ({
                        ...item,
                    })),
                ],
                totalItems: action.totalItems,
                fetchOptions: action.fetchOptions,
            };
        default:
            return state;
    }
}
