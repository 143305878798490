const roles = {
    video_brand_studio: {
        video: {
            manage: 'video_brand_studio.video.manage',
        },
        template: {
            manage: 'video_brand_studio.template.manage',
        },
        handler: {
            admin: 'video_brand_studio.handler.admin',
        },
    },
};

export const orderedRoles = [
    roles.video_brand_studio.handler.admin,
    roles.video_brand_studio.template.manage,
    roles.video_brand_studio.video.manage,
];

export default roles;
