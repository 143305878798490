declare var DEFINE_TESTID_ATTRIBUTES: boolean;

/**
 * generates an object with key 'data-testid' and given value
 * @param name {string} testid
 * @returns {{'data-testid': string}}
 * @public
 */
const generateTestId = (name) => (DEFINE_TESTID_ATTRIBUTES ? { 'data-testid': name } : {});

export default generateTestId;
