import permissions from '../configs/permissions';
import { getDesignsDefaultLink, getNotFoundLink } from './defaultLinks';
import features from '../configs/features';
import store from '../store';

class AuthorizationHelper {
    currentPermissions = [];
    currentRoles = [];
    currentFeatures = [];
    appcuesId = '';

    fallbackUrl = '/';

    setPermissions = (permissions, currentCompany) => {
        this.currentPermissions = permissions;
        this.setFallbackUrl(currentCompany);
    };

    setRoles = (roles) => (this.currentRoles = roles);

    setFeatures = (features) => (this.currentFeatures = features);

    hasPermission = (requestedPermission) => {
        return this.currentPermissions.includes(requestedPermission);
    };

    hasPermissions = (requestedPermissions) => {
        return requestedPermissions.every((rp) => this.currentPermissions.includes(rp));
    };

    hasOneOfThePermissions = (requestedPermissions) => {
        return requestedPermissions.some((rp) => this.currentPermissions.includes(rp));
    };

    isFeatureAvailable = (feature) => this.currentFeatures.includes(feature);

    isOneOfTheFeaturesAvailable = (features) => {
        return features.some((feature) => this.currentFeatures.includes(feature));
    };

    setFallbackUrl = () => {
        const { isAppInitializing } = store.getState().platform;

        if (this.hasPermission(permissions.CREATIVES.READ) || isAppInitializing) {
            this.fallbackUrl = getDesignsDefaultLink();
        } else {
            this.fallbackUrl = getNotFoundLink();
        }
    };

    getFallbackUrl = () => {
        this.setFallbackUrl();
        return this.fallbackUrl;
    };

    appcuesIdentify = (user, company, roles, availableFeatures) => {
        if (!(window.Appcues && window.Appcues.identify)) return;

        const detectRole = () => {
            if (roles.includes('video_brand_studio.handler.admin')) return 'Admin';
            if (roles.includes('video_brand_studio.template.manage')) return 'Designer';
            if (roles.includes('video_brand_studio.video.manage')) return 'Creator';
            return roles.join(', ');
        };
        const toTitleCase = (str) =>
            str
                .toLocaleLowerCase()
                .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                .join(' ');

        const plan_tier = toTitleCase(company.accountType);
        const role = detectRole();
        const isImageOnly = !availableFeatures.includes(features.VIDEOS_ENABLED);

        window.Appcues.identify(user?.bynderUserId || user?.identityId, {
            plan_tier,
            role,
            isImageOnly,
            userType: company?.type,
            systemUser: user?.systemUser,
            activeUser: user?.active,
            firstName: user?.firstName,
            lastName: user?.lastName,
            timeZone: user?.timeZone,

            companyId: company?.id,
            companyName: company?.name,
            bynderPortalUrl: company?.bynderPortalUrl,
            bynderAccountId: company?.bynderAccountId,
            bynderBrandId: company?.bynderBrandId,
        });
    };
}

export default new AuthorizationHelper();
