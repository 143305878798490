import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import { loadingBarReducer } from 'react-redux-loading-bar';

import searchCategory from 'packages/store/searchCategory/searchCategory.reducer';
import newDesign from 'packages/store/newDesign/newDesign.reducer';
import palettes from 'packages/store/brandColors/colorPalettes.reducer';
import importTemplate from 'packages/store/importTemplate/importTemplate.reducer';
import sharing from 'packages/store/sharing/sharing.reducer';
import brandColors from 'packages/store/brandColors/brandColors.reducer';
import categories from 'packages/store/categories/categories.reducer';
import review from 'packages/store/review/review.reducer';
import gallery from './gallery/gallery.reducer';
import * as types from './types';
import userReducer from './user/user.reducer';
import menuReducer from './menu/menu.reducer';
import general from './general/general.reducer';
import creatives from './creatives/creatives.reducer';
import assets from './assets/assets.reducer';
import templates from './templates/templates.reducer';
import creativeEditor from 'packages/store/creativeEditor/creativeEditor.reducer';
import collections from './collections/collections.reducer';
import presets from './presets/presets.reudcer';
import platform from './platform/platform.reducer';
import figma from 'packages/store/figma/figma.reducer';

export default combineReducers({
    loadingBar: loadingBarReducer,
    user: userReducer,
    menu: menuReducer,
    general,
    creatives,
    assets,
    templates,
    creativeEditor: undoable(creativeEditor, {
        ignoreInitialState: true,
        syncFilter: true,
        filter: (action) => {
            if (action.type === types.SET_EDITOR_ELEMENTS) {
                return action.pushToHistory;
            }

            return false;
        },
    }),
    collections,
    presets,
    platform,
    newDesign,
    palettes,
    gallery,
    importTemplate,
    sharing,
    brandColors,
    categories,
    searchCategory,
    review,
    figma,
});
