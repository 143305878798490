import * as types from '../types';

const initialState = {
    portalDomain: null,
    hostUrl: '',
    accessToken: null,
    bynderAccessToken: null,
    hostPort: null,
    isAppInitializing: true,
};

export default function platformReducer(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_PLATFORM_OPTIONS:
            return {
                ...state,
                ...action.options,
            };
        case types.LOGOUT:
            return {
                ...state,
                accessToken: null,
                bynderAccessToken: null,
            };
        case types.TOGGLE_APP_INITIALIZING:
            return {
                ...state,
                isAppInitializing: action.state,
            };
        default:
            return state;
    }
}
