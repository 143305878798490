import * as types from '../types';
import { readCookie } from '../../helpers/cookie';

const initialState = {
    currentPage: null,
    titleEditing: false,
    titleEditError: null,
    calendar: JSON.parse(readCookie('calendar')) || {},
    isEditingBatchComponent: false,
    isOnDemandPageActive: false,
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case types.CALENDAR_CHANGE:
            return {
                ...state,
                calendar: action.calendar,
            };
        case types.SET_STATUS_SWITCH:
            return {
                ...state,
                toggleItemStatus: action.toggleItemStatus,
            };
        case types.TOGGLE_STATUS_SWITCH:
            return {
                ...state,
                toggleItemStatus: { ...state.toggleItemStatus, checked: action.checked },
            };
        case types.EDIT_TITLE_SUCCESS:
            return {
                ...state,
                titleEditing: false,
                titleEditError: null,
                currentPage: { ...state.currentPage, title: action.value },
            };
        case types.EDIT_TITLE_FAIL:
            return {
                ...state,
                titleEditError: action.error,
            };
        case types.EDIT_TITLE_TOGGLE:
            return {
                ...state,
                titleEditing: action.value === undefined ? !state.titleEditing : action.value,
            };
        case types.PAGE_CHANGE:
            return {
                ...state,
                currentPage: action.page,
                titleEditing: false,
                titleEditError: null,
            };

        case types.BATCH_IS_EDITING_COMPONENT:
            return {
                ...state,
                isEditingBatchComponent: action.isEditingBatchComponent,
            };
        case types.TOGGLE_ON_DEMAND_PAGE_STATE:
            return {
                ...state,
                isOnDemandPageActive: action.isOnDemandPageActive,
            };
        default:
            return state;
    }
}
