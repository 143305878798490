import { MediaTypeSelection } from 'packages/pages/designCreateModal/components/Blank/types';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';
import { actionTypes, NewDesignState } from './types';

const initialState: NewDesignState = {
    activePage: DesignSubPages.ALL,
    history: [],
    blank: {
        selectedType: null,
        [MediaTypeSelection.VIDEO]: null,
        [MediaTypeSelection.IMAGE]: null,
    },
    addingInProgress: false,
};

export default function newDesignReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NEW_DESIGN_SET_PAGE: {
            const prevPage = state.activePage;

            return {
                ...state,
                activePage: action.page,
                history: [prevPage, ...state.history].slice(0, 3),
            };
        }
        case actionTypes.NEW_DESIGN_BLANK_SELECT:
            return {
                ...state,
                blank: {
                    ...state.blank,
                    selectedType: action.selectedType,
                },
            };
        case actionTypes.NEW_DESIGN_BLANK_DESELECT: {
            if (action.softMode) {
                return {
                    ...state,
                    blank: {
                        ...state.blank,
                        selectedType: null,
                    },
                };
            }

            return {
                ...state,
                blank: {
                    selectedType: null,
                    [MediaTypeSelection.VIDEO]: null,
                    [MediaTypeSelection.IMAGE]: null,
                },
            };
        }
        case actionTypes.NEW_DESIGN_BLANK_SET_VALUE:
            return {
                ...state,
                blank: {
                    ...state.blank,
                    [action.selectedType]: action.item,
                },
            };
        case actionTypes.NEW_DESIGN_BLANK_CREATE_REQUEST:
            return {
                ...state,
                addingInProgress: true,
            };
        case actionTypes.NEW_DESIGN_BLANK_CREATE_SUCCESS:
            return {
                ...state,
                addingInProgress: false,
            };
        case actionTypes.NEW_DESIGN_BLANK_CREATE_FAILURE:
            return {
                ...state,
                addingInProgress: false,
            };
        default:
            return state;
    }
}
