export const SEND_AMPLITUDE_DESIGN_EVENT = 'SEND_AMPLITUDE_DESIGN_EVENT';

export enum AMPLITUDE_TYPES {
    CREATE_DESIGN = 'Create design',
    DUPLICATE_DESIGN = 'Duplicate design',
    PUBLISH_DESIGN = 'Republish design',
    SAVE_DESIGN = 'Save design',
    SAVE_AS_TEMPLATE = 'Create template',
    OPEN_EDITOR = 'Edit design',
    OPEN_DESIGN = 'Open design',
    SHARE_DESIGN = 'Share design',
    PREVIEW_DESIGN = 'Preview design',
    NEW_VARIATION = 'Create variation',
    SAVE_VARIATION = 'Save variation',
    DOWNLOAD_VARIATION = 'Download variation data',
    UPLOAD_VARIATION = 'Upload variation data',
    NEW_EXPORT = 'Create export',
    DELETE_EXPORT = 'Delete export',
    DOWNLOAD_EXPORT = 'Download export',
}
