import { ComponentType, lazy, LazyExoticComponent } from 'react';
import roles from '~/configs/roles';

export const ABORT_ERROR_NAME = 'AbortError';

export const isTrialAccountStorageFn = (state) => {
    return (
        state.user.accessibleFeatures.includes('trial_enabled') &&
        state.user.roles.includes(roles.video_brand_studio.video.manage)
    );
};

export const catchResponseError = (err) => {
    if (ENVIRONMENT === 'production') {
        return;
    }

    if (err.name === ABORT_ERROR_NAME) {
        return;
    }

    console.error(err);
};

export const makeAbortable = <T>(promise: Promise<T>, abortController: AbortController) => {
    const abort = new Promise<never>((_, reject) => {
        const listener = () => {
            // get the right AbortError and reject our promise with it
            try {
                abortController.signal.throwIfAborted();
            } catch (err) {
                reject(err);
            }
        };

        abortController.signal.addEventListener('abort', listener);
    });

    return Promise.race([abort, promise]);
};

export const ignoreAbortErrors = (err: unknown) => {
    if (err instanceof DOMException && err.name === ABORT_ERROR_NAME) {
        return;
    }

    throw err;
};

// With the lazy component we also add a method call prefetch ,
// when called it just triggers the async function called factory to import the asset,
// but still doesn’t render it.
// https://www.maddhruv.dev/blog/Prefetching-React-Lazy
export const lazyPrefetching = (componentImport) => {
    const factory = async () => {
        try {
            return await componentImport();
        } catch (error) {
            console.error(error);
        }
    };

    const Component = lazy(factory);

    Component.prefetch = factory;

    return <LazyExoticComponent<ComponentType<any>> & { prefetch: () => Promise<any> }>Component;
};
