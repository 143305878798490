import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import * as types from '../types';
import { CREATIVES_PENDING_STATUSES } from '../creatives/creatives.reducer';

const initialState = {
    collections: [],
    collection: null,
    isFetching: false,
    checked: [],
    allChecked: false,
    totalItems: 0,
    searching: false,
    fetchOptions: {
        sort: ['date_created', 'desc'],
        size: 40,
        page: 0,
        search: '',
        searchTime: null,
        includeCollectionId: null,
        type: null,
        requestedType: null, // for race condition between pages
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
    },
    isCollectionFetching: false,
    renamingInProgress: false,
    deleteInProgress: false,
    creatingInProgress: false,
    addingInProgress: false,
    removingFromCollectionInProgress: false,
    editingInProgress: false,
    pendingCollectionThumbnails: [],
};

export const COLLECTION_DESIGNS_PENDING_STATUSES = ['PROCESSING'];

export default function connections(state = initialState, action) {
    switch (action.type) {
        case types.COLLECTIONS_RESET:
            return {
                ...initialState,
            };
        case types.COLLECTIONS_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case types.COLLECTION_RESET:
            return {
                ...state,
                collection: null,
            };
        case types.COLLECTIONS_START_FETCH:
            return {
                ...state,
                isFetching: true,
                searching: action.searching,
                collections: action.emptyBeforeFetch ? [] : state.collections,
                fetchOptions: {
                    ...state.fetchOptions,
                    searchTime: action.searchTime,
                    requestedType: action.requestedType,
                },
            };
        case types.COLLECTIONS_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case types.COLLECTIONS_SUCCESS_FETCH: {
            const newCollections = action.collections.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));

            const actionCollections = action.collections;

            const pendingCollectionThumbnails = actionCollections.reduce((acc, collection) => {
                if (collection.previewItems !== null && collection.previewItems.length) {
                    collection.previewItems.forEach((item) => {
                        if (COLLECTION_DESIGNS_PENDING_STATUSES.includes(item.thumbnailStatus)) {
                            acc.push(item.creativeId);
                        }
                    });
                }
                return acc;
            }, []);

            return {
                ...state,
                isFetching: false,
                collections: [...state.collections, ...newCollections],
                totalItems: action.totalItems,
                fetchOptions: action.fetchOptions,
                pendingCollectionThumbnails,
            };
        }
        case types.COLLECTIONS_CHANGE_SOME: {
            const collections = state.collections.map((collection) => {
                const designIds = action.collectionThumbnails.map((item) => item.creativeId);

                if (!collection.previewItems) {
                    return collection;
                }

                if (collection.previewItems.some((item) => !item.previewUrl)) {
                    return collection;
                }

                const hasPendingPreviews = collection.previewItems.some((item) => designIds.includes(item.creativeId));
                const previewItem = action.collectionThumbnails.find((item) =>
                    collection.previewItems.find((preview) => preview.creativeId === item.creativeId),
                );

                if (
                    !hasPendingPreviews ||
                    !previewItem ||
                    COLLECTION_DESIGNS_PENDING_STATUSES.includes(previewItem.thumbnailStatus)
                ) {
                    return collection;
                }

                return {
                    ...collection,
                    checked: state.checked.includes(collection.id),
                    previewItems: collection.previewItems.map((item) => {
                        return item.creativeId === previewItem.creativeId
                            ? {
                                  ...item,
                                  thumbnailStatus: 'PROCESSED',
                                  previewUrl: previewItem.thumbnailUrl,
                              }
                            : item;
                    }),
                };
            });

            const pendingCollectionThumbnails = collections.reduce((acc, collection) => {
                if (collection.previewItems) {
                    collection.previewItems.forEach((item) => {
                        if (CREATIVES_PENDING_STATUSES.includes(item.thumbnailStatus)) {
                            acc.push(item.creativeId);
                        }
                    });
                }

                return acc;
            }, []);

            return {
                ...state,
                collections,
                pendingCollectionThumbnails,
            };
        }
        case types.COLLECTIONS_TOGGLE_ITEM:
            return {
                ...state,
                checked: state.checked.includes(action.id)
                    ? state.checked.filter((e) => e !== action.id)
                    : [...state.checked, action.id],
                allChecked: state.collections.every((collection) => {
                    return (
                        (collection.checked && collection.id !== action.id) ||
                        (!collection.checked && collection.id === action.id)
                    );
                }),
                collections: state.collections.map((collection) =>
                    collection.id === action.id ? { ...collection, checked: !collection.checked } : collection,
                ),
            };
        case types.COLLECTIONS_TOGGLE_ALL:
            return {
                ...state,
                allChecked: !state.allChecked,
                collections: state.collections.map((collection) => ({ ...collection, checked: !state.allChecked })),
                checked: state.allChecked
                    ? (() => {
                          const res = Object.assign(state.checked);
                          state.collections.forEach((c) => {
                              res.splice(res.indexOf(c.id), 1);
                          });

                          return res;
                      })()
                    : [
                          ...state.checked,
                          ...state.collections.map((c) => c.id).filter((c) => !state.checked.includes(c)),
                      ],
            };
        case types.COLLECTION_START_FETCH:
            return {
                ...state,
                isCollectionFetching: true,
            };
        case types.COLLECTION_SUCCESS_FETCH:
            return {
                ...state,
                isCollectionFetching: false,
                collection: action.collection,
            };
        case types.COLLECTION_FAILURE_FETCH:
            return {
                ...state,
                isCollectionFetching: false,
                collection: action.collection,
            };
        case types.COLLECTION_DELETE_IN_PROGRESS:
            return {
                ...state,
                deleteInProgress: true,
            };
        case types.COLLECTION_DELETE_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.COLLECTION_DELETE_FAILURE:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.COLLECTION_RENAME_IN_PROGRESS:
            return {
                ...state,
                renamingInProgress: true,
            };
        case types.COLLECTION_RENAME_SUCCESS:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.COLLECTION_RENAME_FAILURE:
            return {
                ...state,
                renamingInProgress: false,
            };
        case types.COLLECTION_ADD_CREATIVE_IN_PROGRESS:
            return {
                ...state,
                addingInProgress: true,
            };
        case types.COLLECTION_ADD_CREATIVE_SUCCESS:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.COLLECTION_ADD_CREATIVE_FAILURE:
            return {
                ...state,
                addingInProgress: false,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_IN_PROGRESS:
            return {
                ...state,
                removingFromCollectionInProgress: true,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_SUCCESS:
            return {
                ...state,
                removingFromCollectionInProgress: false,
            };
        case types.COLLECTION_REMOVE_FROM_CREATIVE_FAILURE:
            return {
                ...state,
                removingFromCollectionInProgress: false,
            };
        case types.COLLECTION_ADD_NEW_IN_PROGRESS:
            return {
                ...state,
                creatingInProgress: true,
            };
        case types.COLLECTION_ADD_NEW_SUCCESS:
            return {
                ...state,
                creatingInProgress: false,
            };
        case types.COLLECTION_ADD_NEW_FAILURE:
            return {
                ...state,
                creatingInProgress: false,
            };
        case types.COLLECTION_EDIT_IN_PROGRESS:
            return {
                ...state,
                editingInProgress: true,
            };
        case types.COLLECTION_EDIT_SUCCESS:
            return {
                ...state,
                editingInProgress: false,
            };
        case types.COLLECTION_EDIT_FAILURE:
            return {
                ...state,
                editingInProgress: false,
            };
        default:
            return state;
    }
}
