import * as types from './types';
import { FigmaSelectionState } from './types';

export const SELECTED_FRAMES_LIMIT = 20;

const initialState: FigmaSelectionState = {
    fileResult: { status: 'not started' },
    selectedPage: undefined,
    selectedFrameIds: [],
    isLimitReached: false,
    multiDesignMode: false,
};

export default function figmaSelectedFramesReducer(state = initialState, action): FigmaSelectionState {
    switch (action.type) {
        case types.FIGMA_SET_FILE: {
            const payload: types.FigmaFileResult = action.payload;

            return {
                ...initialState,
                fileResult: payload,
                selectedPage: payload.status === 'success' ? payload.value.pages[0]?.id : undefined,
                multiDesignMode: state.multiDesignMode,
            };
        }
        case types.FIGMA_PAGE_SELECTION: {
            return {
                ...state,
                selectedPage: action.id,
            };
        }
        case types.FIGMA_FRAMES_TOGGLE: {
            const id: string = action.id;
            const isCurrentlySelected = state.selectedFrameIds.includes(id);
            const isLimitReached = state.selectedFrameIds.length >= SELECTED_FRAMES_LIMIT && !isCurrentlySelected;

            let selectedFrameIds = state.selectedFrameIds;

            if (!isLimitReached) {
                selectedFrameIds = isCurrentlySelected
                    ? state.selectedFrameIds.filter((frameId) => frameId !== id)
                    : [...state.selectedFrameIds, id];
            }

            return {
                ...state,
                selectedFrameIds,
                isLimitReached,
            };
        }
        case types.FIGMA_CLOSE_LIMIT_REACHED_MODAL: {
            return {
                ...state,
                isLimitReached: false,
            };
        }
        case types.FIGMA_SET_MULTI_DESIGN_MODE: {
            return {
                ...state,
                multiDesignMode: action.multiDesignMode,
            };
        }
        default: {
            return state;
        }
    }
}
