import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setAutoFreeze } from 'immer';
import { DesignSystemProvider } from '@bynder/design-system';
import { LocalizationProvider } from '@bynder/localization';
import { initSentry } from './initSentry';

import HistoryListener from 'packages/pages/HistoryListener';
import store from '~/store';
import initServerConnector from '~/initServerConnector';
import { checkPlatformUsage } from '~/store/platform/platform.actions';
import fetchFallback from '~/helpers/localization';

import iconDefinitions from '!!raw-loader!./assets/images/icon-definitions.svg';
import './main.scss';
import 'requestidlecallback-polyfill';

if (window !== window.parent) {
    initSentry();
    store.dispatch(checkPlatformUsage());
    initServerConnector();
    setAutoFreeze(false);

    ReactDOM.createRoot(document.querySelector('#root')).render(
        <>
            <span dangerouslySetInnerHTML={{ __html: iconDefinitions }} />
            <DesignSystemProvider>
                <LocalizationProvider fetchFallback={fetchFallback} project="creative-studio-frontend" forceFallback>
                    <Provider key="provider" store={store}>
                        <Router>
                            <Suspense fallback={null}>
                                <Switch>
                                    <Route path="/" component={HistoryListener} />
                                </Switch>
                            </Suspense>
                        </Router>
                    </Provider>
                </LocalizationProvider>
            </DesignSystemProvider>
        </>,
    );
}
