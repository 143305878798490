import { actionTypes, SharingState } from './types';

const initialState: SharingState = {
    entityType: undefined,
    entityId: undefined,
    entityGrantedPermissions: [],
    loading: false,
    saving: false,
    error: undefined,
    searchIdentities: {
        loading: false,
        error: undefined,
        searchTime: 0,
        identities: {
            users: [],
            groups: [],
            profiles: [],
        },
    },
    transferInProgress: false,
};

export default function sharingReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_REQUEST: {
            return {
                ...state,
                searchIdentities: {
                    ...state.searchIdentities,
                    loading: true,
                },
            };
        }
        case actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_SUCCESS: {
            return {
                ...state,
                searchIdentities: {
                    ...state.searchIdentities,
                    loading: false,
                    identities: action.identities,
                },
            };
        }
        case actionTypes.SHARING_FETCH_BYNDER_IDENTITIES_FAILURE: {
            return {
                ...state,
                searchIdentities: {
                    ...state.searchIdentities,
                    loading: false,
                    error: action.error,
                },
            };
        }
        case actionTypes.SHARING_CREATE_REQUEST: {
            return {
                ...state,
                saving: true,
                error: undefined,
            };
        }
        case actionTypes.SHARING_CREATE_FAILURE: {
            return {
                ...state,
                saving: false,
                error: undefined,
            };
        }
        case actionTypes.SHARING_CREATE_SUCCESS: {
            return {
                ...state,
                saving: false,
            };
        }
        case actionTypes.SHARING_FETCH_REQUEST: {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        }
        case actionTypes.SHARING_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: undefined,
            };
        }
        case actionTypes.SHARING_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                entityType: action.entityType,
                entityId: action.entityId,
                entityGrantedPermissions: action.entityGrantedPermissions,
            };
        }
        case actionTypes.SHARING_DROP_STATE: {
            return {
                ...state,
                entityType: undefined,
                entityId: undefined,
                entityGrantedPermissions: [],
                loading: false,
                saving: false,
                transferInProgress: false,
                error: undefined,
            };
        }
        case actionTypes.SHARING_DROP_SEARCH_RESULTS: {
            return {
                ...state,
                searchIdentities: {
                    loading: false,
                    error: undefined,
                    identities: {
                        users: [],
                        groups: [],
                        profiles: [],
                    },
                },
            };
        }
        case actionTypes.OWNERSHIP_TRANSFER_REQUEST: {
            return {
                ...state,
                entityId: action.entityId,
                entityName: action.entityName,
                transferInProgress: true,
                error: undefined,
            };
        }
        case actionTypes.OWNERSHIP_TRANSFER_REQUEST_SUCCESS: {
            return {
                ...state,
                transferInProgress: false,
                error: false,
            };
        }
        case actionTypes.OWNERSHIP_TRANSFER_REQUEST_FAILURE: {
            return {
                ...state,
                transferInProgress: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}
