import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import type { AccountType } from 'packages/hooks/useAccessRights/types';
import { StatusFilterType } from 'packages/pages/components/filters/components/Status/types';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { EntityGrantedPermissions } from '../sharing/types';
import { ReviewStatus } from '../review/types';

export enum CreativeStatus {
    PUBLISHED = 'PUBLISHED',
    DRAFT = 'DRAFT',
    ARCHIVED = 'ARCHIVED',
}

export type ApprovalState = {
    actionDate: string;
    actor: any;
    bynderUserId?: string;
    email?: string;
    firstName?: string;
    identityId: string;
    lastName?: string;
    securityIdentityType: 'USER' | 'PROFILE' | 'GROUP';
    comment: string | null;
    status: 'DRAFT' | 'IN_REVIEW' | 'EDITS_REQUESTED' | 'APPROVED' | 'NO_STATUS';
};

export type Creative = {
    id: number;
    name: string;
    customer: {
        id: number;
        name: string;
        bynderBrandId?: string;
        bynderAccountId?: string;
        bynderPortalUrl?: string;
        accountType: AccountType;
        type: 'BYNDER';
        duplicationRequestStatus: 'CREATED' | 'IN_PROGRESS' | 'DUPLICATED' | 'FAILED';
        apiVersion: string;
    };
    applyVersioning: boolean;
    apiVersion: string;
    firstPublishDate: string;
    lastPublishDate: string;
    status: CreativeStatus;
    approvalStatus?: ReviewStatus;
    approvalState?: ApprovalState;
    aspectRatio: string;
    containsTransparency: boolean;
    width: number;
    height: number;
    imageElementCount: number;
    textElementCount: number;
    videosCount: number;
    updated: string;
    versionId: number;
    created: string;
    thumbnailImage: string;
    thumbnailStatus: 'PROCESSING' | 'PROCESSED' | 'FAILED' | 'UNKNOWN' | 'NOT_FOUND';
    type: 'REGULAR' | 'TEMPLATE' | 'GALLERY' | 'GALLERY_GLOBAL';
    creativeType: 'IMAGE' | 'VIDEO';
    hasPublishedVideo: boolean;
    data_schema: any;
    corruptionReason: string;
    checked?: boolean;
    duration?: number;
    frameRate?: number;
    projectName: string;
    projectId: number;
    grantedPermissions: EntityGrantedPermissions[];
};

export type CreativesState = {
    creatives: Creative[];
    checked: Creative[];
    allChecked: boolean;
    dynamicFields: any;
    creativeFetched: boolean;
    creativesFetched: boolean;
    creative?: Creative;
    generateSuccess: boolean;
    options: {
        filter: 'all';
        creativeType?: MediaFilterType;
        search: string;
        searchTime?: number;
        sort: [SortingFilterType, OrderFilterType];
        page: number;
        size: number;
        ownershipSource: OwnershipFilterType;
        approvalStatus?: StatusFilterType;
    };
    searching: boolean;
    totalCount: number;
    loadingData: boolean;
    firstLoad: boolean;
    duplicationInProgress: boolean;
    deleteInProgress: boolean;
    renamingInProgress: boolean;
    addingInProgress: boolean;
    pendingDesignSavingRequest: boolean;
};
