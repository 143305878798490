import jwt_decode from 'jwt-decode';
import store from 'store2';
import reduxStore from '../store';
import * as types from '../store/types';

class AuthenticationHelper {
    getToken = (): string => reduxStore.getState().platform.accessToken;

    setToken = async (token: string): Promise<void> => {
        await reduxStore.dispatch({
            type: types.UPDATE_PLATFORM_OPTIONS,
            options: {
                accessToken: token,
            },
        });
    };

    // TODO: This a big problem since token not always in place. Better to resolve it via BE
    getProfiles = () => {
        if (!this.getToken()) {
            return [];
        }

        const decoded = jwt_decode(this.getToken()) as any;

        return decoded && decoded.bynderProfileId ? decoded.bynderProfileId : [];
    };

    isLoggedIn = (): boolean => {
        const token = store.get('accessToken');

        return !!token;
    };
}

export default new AuthenticationHelper();
