import { UserState } from './types';

export const getUser = (state): UserState => state.user;
export const getIdentityId = (state) => getUser(state)?.user?.identityId;
export const getUserTimeZone = (state): string => state.user && state.user.user && state.user.user.timeZone;
export const customerIdSelector = (state): UserState['currentCompany'] => state.user.currentCompany;
export const backToOldMessageSelector = (state): UserState['isShowBackToOldMessage'] =>
    state.user.isShowBackToOldMessage;

/** Do not use directly. Use access rights hook */
export const isPlatformAllowedSelector = (state) => {
    return (features: string[], strict = true): boolean => {
        const fn = strict ? 'every' : 'some';

        return features[fn]((feature) => state.user.accessibleFeatures.includes(feature));
    };
};

export const isBynderScopeAllowedSelector = (state) => {
    return (scopes: string[]): boolean => scopes.some((scope) => state.user.bynderScopes.includes(scope));
};

/** Do not use directly. Use access rights hook */
export const isAccountRoleAssignedSelector = (state) => {
    return (role: string): boolean => {
        if (state?.user?.roles) {
            return state.user.roles.includes(role);
        }

        return false;
    };
};

export const getFonts = (state: { user: UserState }) => state.user.fonts;
export const getSplitFeatures = (state: { user: UserState }) => state.user.splitFeatures;
