const filtersStorage = () => {
    const getFiltersFromStorage = (page) => {
        try {
            const stored = sessionStorage.getItem(page);
            if (!stored) {
                return undefined;
            }

            return JSON.parse(stored);
        } catch (err) {
            console.error(err);
            return undefined;
        }
    };

    const setFiltersToStorage = (page, value) => {
        try {
            sessionStorage.setItem(page, JSON.stringify(value));
        } catch (err) {
            console.error(err);
        }
    };

    const clearFiltersFromStorage = (page) => {
        try {
            sessionStorage.removeItem(page);
        } catch (err) {
            console.error(err);
        }
    };

    return {
        getFiltersFromStorage,
        setFiltersToStorage,
        clearFiltersFromStorage,
    };
};

export default filtersStorage;
