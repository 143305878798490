export enum AssetType {
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    PSD = 'PSD',
    SKETCH = 'SKETCH',
}

export enum AssetStatus {
    CREATED = 'CREATED',
    UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    PENDING_PROCESSING = 'PENDING_PROCESSING',
    RETRY_PROCESSING = 'RETRY_PROCESSING',
    PROCESSED = 'PROCESSED',
    PROCESSING_IN_PROGRESS = 'PROCESSING_IN_PROGRESS',
    FAILED = 'FAILED',
}
