import * as types from '../types';

const initialState = {
    items: null,
    selectedItem: null,
    isFetching: false,
    requestingRename: false,
    options: {
        search: '',
        sort: ['updated', 'DESC'],
        page: 0,
        size: 20,
    },
};

function nullToEmptyArray(arr) {
    return arr ? arr : [];
}

export default function presetsReducer(state = initialState, action) {
    switch (action.type) {
        case types.PRESETS_START_FETCH:
            return {
                ...state,
                isFetching: true,
                items: action.emptyBeforeFetch ? [] : state.items,
                options: action.options,
            };

        case types.PRESETS_SUCCESS_FETCH:
            return {
                ...state,
                items: [...nullToEmptyArray(state.items), ...action.items],
                isFetching: false,
                totalItems: action.totalItems,
            };
        case types.PRESETS_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case types.PRESETS_RENAME_REQUEST: {
            return {
                ...state,
                requestingRename: true,
            };
        }
        case types.PRESETS_RENAME_SUCCESS: {
            const items = state.items.map((item) =>
                item.id === action.id
                    ? {
                          ...item,
                          name: action.name,
                      }
                    : item,
            );

            return {
                ...state,
                items,
                requestingRename: false,
            };
        }
        case types.PRESETS_RENAME_FAILURE: {
            return {
                ...state,
                requestingRename: false,
            };
        }
        case types.PRESETS_SET_ITEMS:
            return {
                ...state,
                items: action.items,
                selectedItemId: action.selectedItemId,
                totalItems: typeof action.totalItems === 'number' ? action.totalItems : state.totalItems,
            };
        default:
            return state;
    }
}
