import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class GroupsService extends ProxyServerConnector {
    constructor(path = '/weadapt-users/bynder/groups') {
        super(path);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getGroups() {
        // @ts-ignore
        return this.gateway.get('');
    }
}

export default new GroupsService();
