export const onFailedToFetch: CustomEvent = new CustomEvent('onFailedToFetch');
export const onFailedToParseJson: CustomEvent = new CustomEvent('onFailedToParseJson');
export const onLogout: CustomEvent = new CustomEvent('onLogout'); //logout
export const onForbidden: CustomEvent = new CustomEvent('onForbidden'); //forbidden
export const onNotFound: CustomEvent = new CustomEvent('onNotFound'); //not found
export const onRefreshTokenRequired: CustomEvent = new CustomEvent('onRefreshTokenRequired'); //need to refresh the token
export const onInternalError: CustomEvent = new CustomEvent('onInternalError'); // internal error
export const onBeforeRequest: CustomEvent = new CustomEvent('onBeforeRequest');
export const onAfterRequest: CustomEvent = new CustomEvent('onAfterRequest');

export default {
    onFailedToFetch,
    onFailedToParseJson,
    onLogout,
    onForbidden,
    onNotFound,
    onRefreshTokenRequired,
    onInternalError,
    onBeforeRequest,
    onAfterRequest,
};
