const permissions = {
    CREATIVES: {
        READ: 'creatives:read',
        WRITE: 'creatives:write',
        DELETE: 'creatives:delete',
    },
    CREATIVES_SPECIFICATIONS: {
        READ: 'creative-specifications:read',
        WRITE: 'creative-specifications:write',
        DELETE: 'creative-specifications:delete',
    },
    TEMPLATES: {
        READ: 'templates:read',
        WRITE: 'templates:write',
        DELETE: 'templates:delete',
    },
    GALLERY_TEMPLATES: {
        READ: 'gallery-templates:read',
    },
    PRESETS: {
        READ: 'template-presets:read',
        WRITE: 'template-presets:write',
        DELETE: 'template-presets:delete',
    },
    VIDEOS: {
        READ: 'videos:read',
        CREATE: 'videos:create',
        RENAME: 'videos:rename',
        PUSH: 'videos:push',
        DELETE: 'videos:delete',
        DOWNLOAD: 'google-storage:read',
    },
    ASSETS: {
        READ: 'assets:read',
        WRITE: 'assets:write',
        DELETE: 'assets:delete',
    },
};

export default permissions;
