import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class SplitService extends ProxyServerConnector {
    constructor() {
        super('');
    }

    retrieveFeatureTreatment({ portalDomain, bynderAccessToken }, splitFeatureFlags) {
        return this.post('/split-treatment', { portalDomain, bynderAccessToken, splitFeatureFlags });
    }
}

export default new SplitService();
