import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import type { AccountType } from 'packages/hooks/useAccessRights/types';
import { CollectionSubType, CollectionType } from '../collections/types';
import { EntityGrantedPermissions } from '../sharing/types';
import { Template } from '../templates/types';

export type Category = {
    id: number;
    name: string;
    checked?: boolean;
    type: CollectionType;
    subType: CollectionSubType;
    customer: {
        id: number;
        name: string;
        bynderBrandId?: string;
        bynderAccountId?: string;
        bynderPortalUrl?: string;
        accountType: AccountType;
        type: 'BYNDER';
        duplicationRequestStatus?: 'CREATED' | 'IN_PROGRESS' | 'DUPLICATED' | 'FAILED';
        apiVersion?: string;
    };
    system: boolean;
    created: string;
    updated: string;
    videoCreativesCount: number;
    imageCreativesCount: number;
    previewItems: {
        creativeId: number;
        previewUrl: string;
        thumbnailStatus: 'PROCESSING' | 'PROCESSED' | 'UKNOWN' | 'NOT_FOUND' | 'FAILED';
    }[];
    grantedPermissions: EntityGrantedPermissions[];
    templates?: Template[];
    coverImageUrl?: string;
    coverBgColor?: string;
    fileName?: string;
};

export type CategoriesState = {
    categories: Category[];
    category: Category | null;
    checked: Category[];
    allChecked: boolean;
    isFetching: boolean;
    totalItems: number;
    searching: boolean;
    fetchOptions: {
        sort: string[];
        size: number;
        page: number;
        search: string;
        searchTime?: number;
        includeCollectionId: boolean | null;
        type?: CollectionType;
        subType?: CollectionSubType;
        ownershipSource: OwnershipFilterType;
        creativeType?: MediaFilterType;
        includeEmptyCollection: boolean;
    };
    editingInProgress: boolean;
    deleteInProgress: boolean;
    creatingInProgress: boolean;
    movingInProgress: boolean;
    editingDetailsInProgress: boolean;
    categoriesErrors?: any;
    categoryErrors?: any;
    pendingCategoryThumbnails: number[];
    editingTemplateInProgress: boolean;
};

export enum actionTypes {
    CATEGORIES_START_FETCH = 'CATEGORIES_START_FETCH',
    CATEGORIES_FAIL_FETCH = 'CATEGORIES_FAIL_FETCH',
    CATEGORIES_SUCCESS_FETCH = 'CATEGORIES_SUCCESS_FETCH',
    CATEGORIES_POPULATE = 'CATEGORIES_POPULATE',
    CATEGORIES_RESET = 'CATEGORIES_RESET',
    CATEGORIES_START_ADD_NEW = 'CATEGORIES_START_ADD_NEW',
    CATEGORIES_ADD_NEW_SUCCESS = 'CATEGORIES_ADD_NEW_SUCCESS',
    CATEGORIES_ADD_NEW_FAILURE = 'CATEGORIES_ADD_NEW_FAILURE',
    CATEGORY_START_FETCH = 'CATEGORY_START_FETCH',
    CATEGORY_FAIL_FETCH = 'CATEGORY_FAIL_FETCH',
    CATEGORY_SUCCESS_FETCH = 'CATEGORY_SUCCESS_FETCH',
    CATEGORY_RESET = 'CATEGORY_RESET',
    CATEGORY_RENAME_IN_PROGRESS = 'CATEGORY_RENAME_IN_PROGRESS',
    CATEGORY_RENAME_SUCCESS = 'CATEGORY_RENAME_SUCCESS',
    CATEGORY_RENAME_FAILURE = 'CATEGORY_RENAME_FAILURE',
    CATEGORY_DELETE_IN_PROGRESS = 'CATEGORY_DELETE_IN_PROGRESS',
    CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS',
    CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE',
    CATEGORY_ADD_TEMPLATE_IN_PROGRESS = 'CATEGORY_ADD_TEMPLATE_IN_PROGRESS',
    CATEGORY_ADD_TEMPLATE_SUCCESS = 'CATEGORY_ADD_TEMPLATE_SUCCESS',
    CATEGORY_ADD_TEMPLATE_FAILURE = 'CATEGORY_ADD_TEMPLATE_SUCCESS',
    CATEGORY_REMOVE_FROM_TEMPLATE_IN_PROGRESS = 'CATEGORY_REMOVE_FROM_TEMPLATE_IN_PROGRESS',
    CATEGORY_REMOVE_FROM_TEMPLATE_SUCCESS = 'CATEGORY_REMOVE_FROM_TEMPLATE_SUCCESS',
    CATEGORY_REMOVE_FROM_TEMPLATE_FAILURE = 'CATEGORY_REMOVE_FROM_TEMPLATE_FAILURE',
    CATEGORIES_RESET_OWNERSHIP = 'CATEGORIES_RESET_OWNERSHIP',
    CATEGORY_EDIT_IN_PROGRESS = 'CATEGORY_EDIT_IN_PROGRESS',
    CATEGORY_EDIT_SUCCESS = 'CATEGORY_EDIT_SUCCESS',
    CATEGORY_EDIT_FAILURE = 'CATEGORY_EDIT_FAILURE',
    CATEGORIES_CHANGE_SOME = 'CATEGORIES_CHANGE_SOME',
    CATEGORY_TEMPLATE_REMOVE = 'CATEGORY_TEMPLATE_REMOVE',
    CATEGORY_SET_RENAME_TEMPLATE_STATUS = 'CATEGORY_SET_RENAME_TEMPLATE_STATUS',
    CATEGORY_UPDATE_TEMPLATES = 'CATEGORY_UPDATE_TEMPLATES',
}
