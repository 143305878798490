import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
// @ts-ignore
import appVersion from '!!raw-loader!../app-version';

export const initSentry = () => {
    if (window.location.hostname === 'localhost') {
        return;
    }

    const client = Sentry.getCurrentHub().getClient();

    if (client && client.getOptions().dsn === SENTRY_DSN) {
        return;
    }

    Sentry.init({
        environment: ENVIRONMENT,
        release: `studio@${appVersion}`,
        dist: appVersion,
        dsn: SENTRY_DSN,
        autoSessionTracking: true,
        integrations: [browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
        tracesSampleRate: 1.0,
    });
};
