import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { getUser as getUserSelector } from 'packages/store/user/user.selectors';
import AuthenticationHelper from '~/helpers/AuthenticationHelper';
import Loader from '~/common/loader/Loader';
import generateTestId from '~/helpers/testIdHelpers';

const loaderTestId = generateTestId('old-page-loader');

const PrivateRoute = (props) => {
    const { component: RouteComponent, ...rest } = props;
    const { user } = useSelector(getUserSelector);
    const hasToken = AuthenticationHelper.isLoggedIn();

    if (
        !user &&
        !hasToken &&
        props.location.pathname !== '/' &&
        (props.location.pathname !== '/designs' ||
            (props.location.pathname === '/designs' && props.location.search !== '')) &&
        props.location.pathname !== '/shared'
    ) {
        // TODO: when all pages will be covered by skeleton this if sections can be deprecated
        return (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center" {...loaderTestId}>
                <Loader size={50} />
            </div>
        );
    } else if (!user) {
        if (
            hasToken &&
            props.location.pathname !== '/' &&
            (props.location.pathname !== '/designs' ||
                (props.location.pathname === '/designs' && props.location.search !== '')) &&
            props.location.pathname !== '/shared'
        ) {
            // TODO: when all pages will be covered by skeleton this if sections can be deprecated
            return (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center" {...loaderTestId}>
                    <Loader size={50} />
                </div>
            );
        }
    }

    return <Route {...rest} render={(routeProps) => <RouteComponent {...routeProps} />} />;
};

export default PrivateRoute;
