import { actionTypes, BrandColorsState } from './types';

const initialState: BrandColorsState = {
    colors: [],
    color: null,
    isFetching: false,
    totalItems: 0,
    editingInProgress: false,
    deleteInProgress: false,
    creatingInProgress: false,
    colorsErrors: undefined,
    colorErrors: undefined,
};

export default function brandColor(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_BRAND_COLORS_REQUEST: {
            return {
                ...state,
                isFetching: true,
                totalItems: 0,
                colors: [],
                colorsErrors: undefined,
            };
        }
        case actionTypes.GET_BRAND_COLORS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                totalItems: action.totalItems,
                colors: action.colors,
            };
        }
        case actionTypes.GET_BRAND_COLORS_FAILURE: {
            return {
                ...state,
                isFetching: false,
                totalItems: 0,
                colors: [],
                colorsErrors: action.errors,
            };
        }
        case actionTypes.ADD_BRAND_COLOR_REQUEST: {
            return {
                ...state,
                creatingInProgress: true,
            };
        }
        case actionTypes.ADD_BRAND_COLOR_SUCCESS: {
            return {
                ...state,
                creatingInProgress: false,
            };
        }
        case actionTypes.ADD_BRAND_COLOR_FAILURE: {
            return {
                ...state,
                creatingInProgress: false,
                color: null,
            };
        }
        case actionTypes.EDIT_BRAND_COLOR_REQUEST:
            return {
                ...state,
                editingInProgress: true,
            };
        case actionTypes.EDIT_BRAND_COLOR_SUCCESS:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.EDIT_BRAND_COLOR_FAILURE:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.DELETE_BRAND_COLOR_REQUEST:
            return {
                ...state,
                deleteInProgress: true,
            };

        case actionTypes.DELETE_BRAND_COLOR_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
            };

        case actionTypes.DELETE_BRAND_COLOR_FAILURE:
            return {
                ...state,
                deleteInProgress: false,
            };
        case actionTypes.UPDATE_ORDER_BRAND_COLOR_SUCCESS: {
            return {
                ...state,
                totalItems: action.totalItems,
                colors: action.colors,
            };
        }
        default:
            return state;
    }
}
