export enum OwnershipFilterType {
    OWNED_BY_ME = 'OWNED_BY_ME',
    OWNED_BY_OTHERS = 'OWNED_BY_OTHERS',
    OWNED_BY_ANYONE = 'OWNED_BY_ANYONE',
    NOT_SHARED_WITH_ME = 'NOT_SHARED_WITH_ME',
}

export type OrderingFilterProps = {
    sortBy: OwnershipFilterType;
    isDisabled?: boolean;
    isDesigns?: boolean;
    onSort: (sortBy: OwnershipFilterType) => void;
};
