import { actionTypes, ImportTemplateState } from './types';

const initialState: ImportTemplateState = {
    type: undefined,
    loading: false,
    progress: 0,
    template: undefined,
    errors: undefined,
    validationErrors: [],
    cancelImportRequest: false,
    designFileAnalysis: undefined,
    addingInProgress: false,
    multiDesignMode: true,
};

export default function importTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.IMPORT_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
                type: action.importType,
                requestTime: action.requestTime,
                template: undefined,
                errors: undefined,
                validationErrors: [],
                cancelImportRequest: false,
            };
        case actionTypes.IMPORT_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                template: action.template,
                validationErrors: action.validationErrors,
            };
        case actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_PROGRESS:
            return {
                ...state,
                progress: action.progress,
            };
        case actionTypes.IMPORT_TEMPLATE_DESIGN_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                designFileAnalysis: action.designFileAnalysis,
                validationErrors: action.validationErrors,
                progress: 0,
            };
        case actionTypes.IMPORT_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                type: undefined,
                designFileAnalysis: undefined,
                validationErrors: [],
                cancelImportRequest: true,
                progress: 0,
                errors: action.errors,
            };
        case actionTypes.IMPORT_TEMPLATE_SET_MULTIDESIGN_MODE:
            return {
                ...state,
                multiDesignMode: action.multiDesignMode,
            };
        case actionTypes.DROP_IMPORTED_TEMPLATE:
            return {
                ...state,
                loading: false,
                type: undefined,
                template: undefined,
                errors: undefined,
                validationErrors: [],
                designFileAnalysis: undefined,
                cancelImportRequest: true,
                progress: 0,
                multiDesignMode: true,
            };
        case actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_REQUEST:
            return {
                ...state,
                addingInProgress: true,
            };
        case actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_SUCCESS:
            return {
                ...state,
                addingInProgress: false,
            };
        case actionTypes.IMPORT_TEMPLATE_CREATE_DESIGNS_FAILURE:
            return {
                ...state,
                addingInProgress: false,
            };
        default:
            return state;
    }
}
