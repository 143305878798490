import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { Category } from '../categories/types';
import { CollectionSubType, CollectionType } from '../collections/types';

export type CategorySearchState = {
    categories: Category[];
    isFetching: boolean;
    totalItems: number;
    searching: boolean;
    fetchOptions: {
        sort: string[];
        size: number;
        page: number;
        search: string;
        searchTime?: number;
        includeCollectionId: boolean | null;
        type?: CollectionType;
        subType?: CollectionSubType;
        ownershipSource: OwnershipFilterType;
        creativeType?: MediaFilterType;
    };
};

export enum searchActionTypes {
    CATEGORIES_SEARCH_START_FETCH = 'CATEGORIES_SEARCH_START_FETCH',
    CATEGORIES_SEARCH_FAIL_FETCH = 'CATEGORIES_SEARCH_FAIL_FETCH',
    CATEGORIES_SEARCH_SUCCESS_FETCH = 'CATEGORIES_SEARCH_SUCCESS_FETCH',
    CATEGORIES_SEARCH_RESET = 'CATEGORIES_SEARCH_RESET',
    CATEGORIES_SEARCH_RESET_OWNERSHIP = 'CATEGORIES_SEARCH_RESET_OWNERSHIP',
}
