import { ReviewState, actionTypes } from './types';

const initialState: ReviewState = {
    entityId: undefined,
    status: undefined,
    comment: undefined,
    actionDate: undefined,
    entityGrantedPermissions: undefined,
    saving: false,
    savingStatus: undefined,
    error: undefined,
};

export default function reviewReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REVIEW_CREATE_REQUEST: {
            return {
                ...state,
                saving: true,
                savingStatus: action.status,
                error: undefined,
            };
        }
        case actionTypes.REVIEW_CREATE_FAILURE: {
            return {
                ...state,
                saving: false,
                savingStatus: undefined,
                error: undefined,
            };
        }
        case actionTypes.REVIEW_CREATE_SUCCESS: {
            const { status, comment, actionDate, entityGrantedPermissions } = action;

            return {
                ...state,
                status,
                comment,
                actionDate,
                saving: false,
                savingStatus: undefined,
                entityGrantedPermissions,
            };
        }
        case actionTypes.REVIEW_CREATE_INIT: {
            return initialState;
        }
        default:
            return state;
    }
}
