import ProxyServerConnector from './connectors/ProxyServerConnector';

class UserService extends ProxyServerConnector {
    constructor(path = '/user') {
        super(path);
    }

    update(data) {
        return this.put('', data).then(
            ({ json: user, status }) => {
                return { user, status };
            },
            (err) => {
                return {};
            },
        );
    }

    getUser() {
        return this.get('');
    }
}

export default new UserService();
