import * as types from '../types';

const initialState = {
    open: false,
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case types.MENU_SET_ACTIVE:
            return {
                ...state,
                activeItem: action.activeItem,
            };
        case types.MENU_TOGGLE:
            return {
                ...state,
                open: action.open,
            };
        default:
            return state;
    }
}
