import { DesignProperties, ExportAdditionalProperties, NewDesign, UserProperties } from '~/store/amplitude/types';
import { ExportJobItemType } from 'packages/variation-export/AllExports/types';

export const throwAmplitudeError = (error: string) => {
    console.log('Could not sent amplitude event:', error);
};

export const getUserProperties = (store): UserProperties => {
    const { user } = store;
    const { roles: userRoles, companies, currentCompany } = user;
    const { accountType } = companies.find((c) => c.id === currentCompany);

    return {
        userRoles,
        accountType,
    };
};

export const getDesignProperties = (newDesign: NewDesign): DesignProperties => {
    const allPages = newDesign.pages.map((page) => `${page.width}x${page.height}`);

    return {
        allPages,
        designType: newDesign.creativeType,
    };
};

export const getAmplitudeDataFromExportJob = (job: ExportJobItemType): ExportAdditionalProperties => {
    const variationPages = {};
    job.jobVariations.forEach((jobVariation) => {
        const { width, height } = jobVariation.variation.renditions[0];
        const variationPageKey = `${width}x${height}`;
        variationPages[variationPageKey] = true;
    });

    return {
        variationAmount: job.jobVariations.length,
        variationPages: Object.keys(variationPages),
        exportFormat: job.renditionConfiguration.displayName,
        exportChannel: job.type,
    };
};
