import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class TrackingService extends ProxyServerConnector {
    constructor(path = '/weadapt-users') {
        super(path);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    registerLogin(data) {
        if (data.bynderUserId) {
            // @ts-ignore
            return this.gateway.post('/register/login', data);
        }

        return null;
    }
}

export default new TrackingService();
