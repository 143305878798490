import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, EmptyState } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconDisabled } from '@bynder/icons';
import { ErrorPage } from '../errors.styled';

const NotFound = () => {
    const { translate } = useTranslate();
    const history = useHistory();

    return (
        <ErrorPage>
            <EmptyState
                icon={<IconDisabled />}
                title={translate('pages.errors.not-found.title')}
                text={translate('pages.errors.not-found.text')}
                actions={
                    <Button
                        variant="secondary"
                        aria-label={translate('pages.errors.not-found.button')}
                        onClick={() => history.replace('/')}
                    >
                        {translate('pages.errors.not-found.button')}
                    </Button>
                }
            />
        </ErrorPage>
    );
};
export default NotFound;
