import React, { Component } from 'react';
import './Loader.scss';

export default class Loader extends Component {
    render() {
        const poster = this.props.poster;
        if (poster) {
            return (
                <div
                    className="h-100 w-100 poster--loader"
                    style={{
                        backgroundImage: `url(${poster})`,
                    }}
                />
            );
        }
        return (
            <div
                className={`position-relative overflow-hidden d-inline-block ${this.props.className || ''}`}
                style={{ width: this.props.size || 40, height: this.props.size || 40 }}
            >
                <div className="loader" />
                <div
                    className="d-flex align-items-center justify-content-center position-absolute h-100 w-100 text-center small"
                    style={{ top: 0 }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
