export enum EntityType {
    CREATIVE = 'CREATIVE',
    COLLECTION = 'COLLECTION',
    TEMPLATE = 'TEMPLATE',
    CATEGORY = 'CATEGORY',
}

export enum IdentityType {
    ALL_USERS = 'ALL_USERS',
    USER = 'USER',
    GROUP = 'GROUP',
    PROFILE = 'PROFILE',
}

export enum IdentityRole {
    OWNER = 'OWNER',
    CONTRIBUTOR = 'CONTRIBUTOR',
    CAN_USE = 'CAN_USE',
    REVIEWER = 'REVIEWER',
}

export type UserSharingRole = {
    userId: string;
    userName: string;
    fullName: string;
    avatarUrl: string;
    email: string;
    createdDate: string;
    preferredLanguage: string;
    publicUser: string;
    legacyId: string;
};

export type GroupSharingRole = {
    groupId: string;
    groupName: string;
};

export type Profiles = {
    profileId: string;
    profileName: string;
    default: true;
};

export type SecurityIdentity = {
    type: IdentityType;
    aclRole: IdentityRole;
    granting: boolean;
    bynderUserId?: string;
};

export type EntityGrantedPermissions = {
    entityId?: number;
    added?: boolean;
    securityIdentity: {
        securityIdentityType: IdentityType;
        identityId?: string;
        bynderUserId?: string;
        firstName?: string;
        lastName?: string;
        fullName?: string;
        email?: string;
        groupId?: string;
        groupName?: string;
        profileId?: string;
        profileName?: string;
    };
    grantedPermissions: Array<{
        permission: IdentityRole;
        granted: boolean;
        projectId?: string | null;
    }>;
    projectGrantedPermissions: Array<{
        permission: IdentityRole;
        granted: boolean;
        projectId?: string | null;
    }>;
};

export type SharingState = {
    entityType?: EntityType;
    entityId?: number;
    entityGrantedPermissions?: EntityGrantedPermissions[];
    loading: boolean;
    saving: boolean;
    error?: any;
    searchIdentities: {
        loading: boolean;
        error?: any;
        searchTime: number;
        identities: {
            users: UserSharingRole[];
            groups: GroupSharingRole[];
            profiles: Profiles[];
        };
    };
    transferInProgress: boolean;
};

export enum actionTypes {
    SHARING_FETCH_REQUEST = 'SHARING_FETCH_REQUEST',
    SHARING_FETCH_SUCCESS = 'SHARING_FETCH_SUCCESS',
    SHARING_FETCH_FAILURE = 'SHARING_FETCH_FAILURE',
    SHARING_CREATE_REQUEST = 'SHARING_CREATE_REQUEST',
    SHARING_CREATE_SUCCESS = 'SHARING_CREATE_SUCCESS',
    SHARING_CREATE_FAILURE = 'SHARING_CREATE_FAILURE',
    SHARING_FETCH_BYNDER_IDENTITIES_REQUEST = 'SHARING_FETCH_BYNDER_IDENTITIES_REQUEST',
    SHARING_FETCH_BYNDER_IDENTITIES_SUCCESS = 'SHARING_FETCH_BYNDER_IDENTITIES_SUCCESS',
    SHARING_FETCH_BYNDER_IDENTITIES_FAILURE = 'SHARING_FETCH_BYNDER_IDENTITIES_FAILURE',
    SHARING_DROP_STATE = 'SHARING_DROP_STATE',
    SHARING_DROP_SEARCH_RESULTS = 'SHARING_DROP_SEARCH_RESULTS',
    OWNERSHIP_TRANSFER_REQUEST = 'OWNERSHIP_TRANSFER_REQUEST',
    OWNERSHIP_TRANSFER_REQUEST_SUCCESS = 'OWNERSHIP_TRANSFER_REQUEST_SUCCESS',
    OWNERSHIP_TRANSFER_REQUEST_FAILURE = 'OWNERSHIP_TRANSFER_REQUEST_FAILURE',
}
