import { FontFamily } from '@bynder-studio/structured-text';

export type UserState = {
    user: any;
    companies: any;
    permissions: string[];
    roles: string[];
    accessibleFeatures: string[];

    resetRequestFail: any;
    updateFail: any;
    currentCompany: number;
    timeZones: any[];

    isActivating: boolean;
    isActivated: boolean;
    tokenValdiationStatus: 'REQUEST' | 'SUCCESS' | 'FAIILURE';

    groups: string[];
    isShowBackToOldMessage: boolean;

    fonts: FontFamily[];
    elementFonts: FontFamily[];
    splitFeatures: Record<ActiveSplits, 'on' | 'off' | 'control'>;
};

export enum ActiveSplits {
    studioInitialTest = 'studio_initial_test',
    DSBrandUpdate = 'ds-brand-update',
}
