import { BlankItem, MediaTypeSelection } from 'packages/pages/designCreateModal/components/Blank/types';
import { DesignSubPages } from 'packages/pages/designCreateModal/components/Sidebar/types';

export type SizeData = { width: number; height: number };
export type BlankItemData = Omit<BlankItem, 'icon'>;
export type NewDesignState = {
    activePage: string;
    history: DesignSubPages[];
    blank: {
        selectedType: MediaTypeSelection | null;
        [MediaTypeSelection.VIDEO]: BlankItemData | null;
        [MediaTypeSelection.IMAGE]: BlankItemData | null;
    };
    addingInProgress: boolean;
};

export enum actionTypes {
    NEW_DESIGN_SET_PAGE = 'NEW_DESIGN_SET_PAGE',
    NEW_DESIGN_BLANK_SELECT = 'NEW_DESIGN_BLANK_SELECT',
    NEW_DESIGN_BLANK_DESELECT = 'NEW_DESIGN_BLANK_DESELECT',
    NEW_DESIGN_BLANK_SET_VALUE = 'NEW_DESIGN_BLANK_SET_VALUE',
    NEW_DESIGN_BLANK_CREATE_REQUEST = 'NEW_DESIGN_BLANK_CREATE_REQUEST',
    NEW_DESIGN_BLANK_CREATE_SUCCESS = 'NEW_DESIGN_BLANK_CREATE_SUCCESS',
    NEW_DESIGN_BLANK_CREATE_FAILURE = 'NEW_DESIGN_BLANK_CREATE_FAILURE',
}
