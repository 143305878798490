import { prop, uniqBy } from 'rambda';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { actionTypes, CategoriesState } from './types';

const initialState: CategoriesState = {
    categories: [],
    category: null,
    checked: [],
    allChecked: false,
    isFetching: false,
    totalItems: 0,
    searching: false,
    fetchOptions: {
        sort: ['date_created', 'desc'],
        size: 1000,
        page: 0,
        search: '',
        includeCollectionId: null,
        type: undefined,
        subType: 'TEMPLATE',
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
        creativeType: undefined,
        includeEmptyCollection: true,
    },
    editingInProgress: false,
    editingTemplateInProgress: false,
    deleteInProgress: false,
    creatingInProgress: false,
    movingInProgress: false,
    editingDetailsInProgress: false,
    categoriesErrors: undefined,
    categoryErrors: undefined,
    pendingCategoryThumbnails: [],
};

export const CATEGORY_TEMPLATES_PENDING_STATUSES = ['PROCESSING'];

export default function categories(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CATEGORIES_RESET:
            return {
                ...initialState,
            };
        case actionTypes.CATEGORIES_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case actionTypes.CATEGORIES_START_FETCH:
            return {
                ...state,
                isFetching: true,
                searching: action.searching,
                categories: action.emptyBeforeFetch ? [] : state.categories,
                fetchOptions: {
                    ...state.fetchOptions,
                },
            };
        case actionTypes.CATEGORIES_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case actionTypes.CATEGORIES_SUCCESS_FETCH: {
            const newCategories = action.categories.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));

            const actionCategories = action.categories;

            const pendingCategoryThumbnails = actionCategories.reduce((acc, category) => {
                if (category.previewItems !== null && category?.previewItems?.length > 0) {
                    category.previewItems.forEach((item) => {
                        if (CATEGORY_TEMPLATES_PENDING_STATUSES.includes(item.thumbnailStatus)) {
                            acc.push(item.creativeId);
                        }
                    });
                }
                return acc;
            }, []);

            return {
                ...state,
                isFetching: false,
                categories: uniqBy(prop('id'), [...state.categories, ...newCategories]),
                totalItems: action.totalItems,
                fetchOptions: action.fetchOptions,
                pendingCategoryThumbnails,
            };
        }
        case actionTypes.CATEGORIES_CHANGE_SOME: {
            const categories = state.categories.map((category) => {
                if (!category?.previewItems === null || !category?.previewItems?.length) {
                    return category;
                }

                if (category?.previewItems.some((item) => !item.previewUrl)) {
                    return category;
                }

                const templateIds = action.categoryThumbnails.map((item) => item.creativeId);
                const hasPendingPreviews = category.previewItems.some((item) => templateIds.includes(item.creativeId));
                const previewItem = action.categoryThumbnails.find((item) =>
                    category.previewItems.some((preview) => preview.creativeId === item.creativeId),
                );

                if (
                    !hasPendingPreviews ||
                    !previewItem ||
                    CATEGORY_TEMPLATES_PENDING_STATUSES.includes(previewItem.thumbnailStatus)
                ) {
                    return category;
                }

                const templates = category.templates?.map((item) => {
                    const categoryThumbnail = action.categoryThumbnails.find(
                        (thumbnail) => thumbnail.creativeId === item.id,
                    );

                    if (categoryThumbnail) {
                        return {
                            ...item,
                            thumbnailStatus: 'PROCESSED',
                            previewUrl: categoryThumbnail.thumbnailUrl,
                        };
                    }

                    return item;
                });

                return {
                    ...category,
                    previewItems: category.previewItems.map((item) => {
                        return item.creativeId === previewItem?.creativeId
                            ? {
                                  ...item,
                                  thumbnailStatus: 'PROCESSED',
                                  previewUrl: previewItem.thumbnailUrl,
                              }
                            : item;
                    }),
                    templates,
                };
            });

            const pendingCategoryThumbnails = categories.reduce((acc, category) => {
                if (category.previewItems !== null && category?.previewItems?.length > 0) {
                    category.previewItems.forEach((item) => {
                        if (CATEGORY_TEMPLATES_PENDING_STATUSES.includes(item.thumbnailStatus)) {
                            acc.push(item.creativeId);
                        }
                    });
                }

                return acc;
            }, [] as Number[]);

            return {
                ...state,
                categories,
                pendingCategoryThumbnails,
            };
        }
        case actionTypes.CATEGORIES_POPULATE: {
            const newCategories = action.categories.map((item) => ({
                ...item,
                checked: state.checked.includes(item.id),
            }));
            return {
                ...state,
                categories: newCategories,
            };
        }
        case actionTypes.CATEGORY_START_FETCH:
            return {
                ...state,
                isFetching: true,
            };
        case actionTypes.CATEGORY_FAIL_FETCH:
            return {
                ...state,
                isFetching: false,
            };
        case actionTypes.CATEGORY_SUCCESS_FETCH:
            return {
                ...state,
                isFetching: false,
                category: action.category,
            };
        case actionTypes.CATEGORIES_START_ADD_NEW:
            return {
                ...state,
                creatingInProgress: true,
            };
        case actionTypes.CATEGORIES_ADD_NEW_SUCCESS:
            return {
                ...state,
                creatingInProgress: false,
            };
        case actionTypes.CATEGORIES_ADD_NEW_FAILURE:
            return {
                ...state,
                creatingInProgress: false,
            };
        case actionTypes.CATEGORY_RENAME_IN_PROGRESS:
            return {
                ...state,
                editingInProgress: true,
            };
        case actionTypes.CATEGORY_RENAME_SUCCESS:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.CATEGORY_RENAME_FAILURE:
            return {
                ...state,
                editingInProgress: false,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_IN_PROGRESS:
            return {
                ...state,
                movingInProgress: true,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_SUCCESS:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_ADD_TEMPLATE_FAILURE:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_IN_PROGRESS:
            return {
                ...state,
                movingInProgress: true,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_SUCCESS:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_REMOVE_FROM_TEMPLATE_FAILURE:
            return {
                ...state,
                movingInProgress: false,
            };
        case actionTypes.CATEGORY_EDIT_IN_PROGRESS:
            return {
                ...state,
                editingDetailsInProgress: true,
            };
        case actionTypes.CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                editingDetailsInProgress: false,
            };
        case actionTypes.CATEGORY_EDIT_FAILURE:
            return {
                ...state,
                editingDetailsInProgress: false,
            };
        case actionTypes.CATEGORY_TEMPLATE_REMOVE:
            return {
                ...state,
                categories: state.categories.map((category) => {
                    if (category.id !== action.categoryId) {
                        return category;
                    }

                    return {
                        ...category,
                        templates: (category.templates || []).filter(
                            (template) => !action.templateIds.includes(template.id),
                        ),
                    };
                }),
            };
        case actionTypes.CATEGORY_SET_RENAME_TEMPLATE_STATUS:
            return {
                ...state,
                editingTemplateInProgress: action.status,
            };
        case actionTypes.CATEGORY_UPDATE_TEMPLATES:
            return {
                ...state,
                categories: action.categories,
                editingTemplateInProgress: false,
            };
        default:
            return state;
    }
}
